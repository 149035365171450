<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
              :class="{ row, error: v$.role.name.$errors.length }"
              :label="$t('forms.name')"
              label-for="name"
              label-cols-md="2"
              content-cols-lg="4"
          >
            <b-form-input v-model="role.name" id="name"/>
            <div
                class="input-errors"
                v-for="error of v$.role.name.$errors"
                :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-card
              :header="$t('forms.permissions')"
              header-tag="header"
              class="roles-card"
          >
            <table class="table table-striped table-roles">
              <thead>
              <tr>
                <th>
                  {{ $t('forms.module') }}
                </th>
                <th>
                  {{ $t('forms.list') }}
                </th>
                <th>
                  {{ $t('forms.listAll') }}
                </th>
                <th>
                  {{ $t('forms.view') }}
                </th>
                <th>
                  {{ $t('forms.create') }}
                </th>
                <th>
                  {{ $t('forms.update') }}
                </th>
                <th>
                  {{ $t('forms.delete') }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item,index) in role.permissions"
                  :key="index"
              >
                <td>
                  {{
                    item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase().replaceAll('_', ' ')
                  }}
                </td>
                <td>
                  <b-form-checkbox v-model="item.LIST"/>
                </td>
                <td>
                  <b-form-checkbox v-model="item.LIST_ALL"/>
                </td>
                <td>
                  <b-form-checkbox v-model="item.VIEW"/>
                </td>
                <td>
                  <b-form-checkbox v-model="item.CREATE"/>
                </td>
                <td>
                  <b-form-checkbox v-model="item.UPDATE"/>
                </td>
                <td>
                  <b-form-checkbox v-model="item.DELETE"/>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card>

          <b-card
              header="Additional permissions"
              header-tag="header"
              class="roles-card"
          >
            <table class="table table-striped table-roles">
              <thead>
              <tr>
                <th>
                  {{ $t('forms.module') }}
                </th>
                <th>
                  Access
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item,index) in role.additionalPermissions"
                  :key="index"
              >
                <td>{{
                    item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase().replaceAll('_', ' ')
                  }}
                </td>
                <td>
                  <b-form-checkbox v-model="item.value"/>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  props: {
    roleObject: {Object, required: true},
    action: {type: String, default: null},
  },
  mixins: [ResourceUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      role: {
        name: {required},
      },
    };
  },
  data() {
    return {
      role: this.roleObject,
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
    role: {
      handler() {
        let i = 0
        this.role.permissions.forEach(element => {
          if (element.UPDATE || element.CREATE) {
            this.role.permissions[i].VIEW = true
          }
          if (element.VIEW || element.DELETE) {
            this.role.permissions[i].LIST = true
          }
          if (element.LIST_ALL) {
            this.role.permissions[i].LIST = true
          }
          i = i + 1
        });
      },
      deep: true
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        if (this.action === "add") {
          this.create(
              this.$Roles,
              this.role,
              this.$t("messages.roleCreated"),
              null,
              this.success,
              this.error
          );
        }
        if (this.action === "update") {
          this.$emit("clearAction");
          this.update(
              this.$Roles,
              this.role.id,
              this.role,
              this.$t("messages.roleUpdated"),
              null,
              this.success,
              this.error
          );
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push("/roles");
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

